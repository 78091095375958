import get from 'lodash.get';

// === Data === //
import { softwareSiteData } from '@graphql/SoftwareSite';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { mainBanner } from '@DTO/sections/MainBanner';
import { fullScreenImage } from '@DTO/sections/FullScreenImage';
import { iconWithCopy } from '@DTO/sections/IconWithCopy';
import { textImageTimeline } from '@DTO/sections/TextImageTimeline';
import { timeline } from '@DTO/sections/Timeline';
import { conversionWith1LightCTA } from '@DTO/sections/ConversionWith1LightCTA';
import { software360Section } from '@DTO/sections/software/Software360Section';
import { faq } from '@DTO/sections/FAQ';
import { softwarePluginsTable } from '@DTO/sections/table/SoftwarePluginsTable';
import { softwareAdditionalNavi } from '@DTO/sections/SoftwareAdditionalNavi';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { getNodeEntity } from '@utils';
import { languagePath } from '@DTO/sections/LanguagePath';

export const SoftwareSiteDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };
  let additionalNaviList;

  if (!data) {
    const pageData = softwareSiteData();
    currentPageData = pageData;
    additionalNaviList = get(currentPageData, 'drupalData.nodeQuery.entities', []).filter(
      (node) => node.uuid !== uuid
    );
  } else {
    currentPageData = data;
    additionalNaviList = get(data, 'drupalData.additionalNavi.entities', []).filter(
      (node) => node.uuid !== uuid
    );
  }
  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, ['fieldCategoryA']),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      fullScreenImage(nodeEntity, 'fieldFullScreenImage'),
      iconWithCopy(nodeEntity, 'fieldIconWithCopy'),
      softwarePluginsTable(nodeEntity, 'fieldSoftwarePluginsTable'),
      textImageTimeline(nodeEntity, 'fieldTextImageTimeline'),
      timeline(nodeEntity, 'fieldTimeline'),
      conversionWith1LightCTA(nodeEntity, 'fieldConversion1Cta'),
      iconWithCopy(nodeEntity, 'fieldIconWithCopy2'),
      textImageTimeline(nodeEntity, 'fieldTextImageTimeline2'),
      iconWithCopy(nodeEntity, 'fieldIconWithCopy3'),
      software360Section(nodeEntity, 'fieldSoftware360Section'),
      conversionWith1LightCTA(nodeEntity, 'fieldConversion1Cta2'),
      faq(nodeEntity, 'fieldFaq'),
      softwareAdditionalNavi(nodeEntity, additionalNaviList, 'fieldSoftwareAdditionalNavi'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
