import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const softwareSiteData = () => {
  const data = useStaticQuery(graphql`
    query softwareSiteQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "software_site" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeSoftwareSite {
              entityLabel

              fieldConversion1Cta {
                entity {
                  ...ParagraphConversion1Cta
                }
              }
              fieldConversion1Cta2 {
                entity {
                  ...ParagraphConversion1Cta2
                }
              }
              fieldFaq {
                entity {
                  ...ParagraphFaq
                }
              }
              fieldFullScreenImage {
                entity {
                  ...ParagraphFullScreenImage
                }
              }
              fieldIconWithCopy {
                entity {
                  ...ParagraphProductIconWithCopy
                }
              }
              fieldIconWithCopy2 {
                entity {
                  ...ParagraphProductIconWithCopy2
                }
              }
              fieldIconWithCopy3 {
                entity {
                  ...ParagraphProductIconWithCopy3
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldSoftware360Section {
                entity {
                  ...ParagraphSoftware360Section
                }
              }
              fieldSoftwareAdditionalNavi {
                entity {
                  ...ParagraphSoftwareAdditionalNavi
                }
              }
              fieldSoftwareNavi {
                entity {
                  ...ParagraphSoftwareNavi
                }
              }
              fieldSoftwarePluginsTable {
                entity {
                  ...ParagraphSoftwarePluginsTable
                }
              }
              fieldTextImageTimeline {
                entity {
                  ...ParagraphTextImageTimeline
                }
              }
              fieldTextImageTimeline2 {
                entity {
                  ...ParagraphTextImageTimeline2
                }
              }
              fieldTimeline {
                entity {
                  ...ParagraphTimeline
                }
              }
              fieldCategoryA {
                entity {
                  title
                  path {
                    alias
                  }
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
