import get from 'lodash.get';

export const timeline = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    description: get(data, 'fieldDescription.processed', ''),
    variant: get(data, 'fieldVariantOfTheNumber', ''),
    items: get(data, 'fieldTimelineItem', []).map((item) => ({
      image: {
        alt: get(item, 'entity.fieldImage.alt', ''),
        url: get(item, 'entity.fieldImage.url', ''),
        title: get(item, 'entity.fieldImage.title', ''),
        webp: get(item, 'entity.fieldImage.derivative.url', ''),
      },
      title: get(item, 'entity.fieldTitle', ''),
      content: get(item, 'entity.fieldCopy.processed'),
    }))
  }
}
