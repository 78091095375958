import get from 'lodash.get';
import * as utils from '@utils';

import iconYes from '@assets/svg/table-icon-yes-green.svg';

import {
  ISoftwarePluginsTable
} from '@components/sections/Table/SoftwarePluginsTable/SoftwarePluginsTable.d';

export const softwarePluginsTable = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const rows = get(data, 'fieldCardWithCollapseTable.value', []);
  const dictionaryYes = get(data, 'fieldDictionaryYes', 'Yes').toLowerCase();

  const componentData: ISoftwarePluginsTable = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    table: {
      columnTitles: get(rows, '["0"]', []),
      tableRows: Object.keys(rows).filter(row => row !== "0" && row !== 'caption').map((row, rowIndex) => {
        const arrRow = rows[row];

        return arrRow.map((cell, cellIndex) => ({
          copy: cell,
          bold: cellIndex === 0,
          icon: utils.dictionaryMatch(cell, dictionaryYes) ? {
            url: iconYes,
            type: 'yes',
          } : undefined
        }))
      }),
      notes: get(data, 'fieldNotes.processed'),
    }
  }

  return componentData;
}