import get from 'lodash.get';

import { ISoftwareAdditionalNavi } from '@components/sections/Software/SoftwareAdditionalNavi/SoftwareAdditionalNavi.d';

export const softwareAdditionalNavi = (nodeData, additionalNaviList, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const mappedData: ISoftwareAdditionalNavi = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    items: additionalNaviList.map(site => {
      const siteData = get(site, 'fieldSoftwareNavi.entity', undefined);
      
      return {
        copy: get(siteData, 'fieldCopy.processed', ''),
        cta: {
          url: get(site, 'path.alias', '/'),
          label: get(siteData, 'fieldCtaText')
        },
        title: get(siteData, 'fieldTitle', ''),
        image: get(siteData, 'fieldImage', undefined) ? {
          url: get(siteData, 'fieldImage.url', ''),
          alt: get(siteData, 'fieldImage.alt', ''),
          title: get(siteData, 'fieldImage.title', ''),
          webp: get(siteData, 'fieldImage.derivative.url', ''),
        } : undefined,
      }
    })
  };

  return mappedData;
};
