import get from 'lodash.get';

export const faq = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    background: get(data, 'fieldBackground[0]') === 'yes',
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    items: get(data, 'fieldFaqItem', []).map((item) => ({
      title: get(item, 'entity.fieldTitle'),
      content: get(item, 'entity.fieldCopy.processed'),
    })),
  }
}