import get from 'lodash.get';

import { ctaVariants } from '@utils';
import { IConversionWith1LightCTA } from '@components/sections/ConversionWith1LightCTA/ConversionWith1LightCTA.d';

export const conversionWith1LightCTA = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IConversionWith1LightCTA = {
    type: get(data, 'entityBundle', 'conversion_with_1_light_cta'),
    headline: get(data, 'fieldHeadline.processed', ''),
    box: {
      description: get(data, 'fieldConversionBoxOne.entity.fieldDescription.processed', 'cta_white'),
      cta: {
        variant: ctaVariants[get(data, 'fieldConversionBoxOne.entity.fieldCtaWhite.entity.entityBundle', 'cta_white')],
        label: get(data, 'fieldConversionBoxOne.entity.fieldCtaWhite.entity.fieldLinkCta.title', ''),
        href: get(data, 'fieldConversionBoxOne.entity.fieldCtaWhite.entity.fieldLinkCta.url.path', '/'),
      }
    }
  }

  return componentData;
}