import get from 'lodash.get';

export const software360Section = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    code: get(data, 'fieldCode', ''),
    description: get(data, 'fieldCopy.processed', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    iconWithCopy: get(data, 'fieldIconWithCopy', []).map((item) => {
      return {
        icon: {
          url: get(item, 'entity.fieldIcon.url', ''),
          alt: get(item, 'entity.fieldIcon.alt', ''),
        },
        name: get(item, 'entity.fieldTitle', ''),
        description: get(item, 'entity.fieldCopy.processed', ''),
        cta: {
          url: get(item, 'entity.fieldLinkCta.url.path', ''),
          label: get(item, 'entity.fieldLinkCta.title', ''),
        },
      };
    }),
  }
}