import get from 'lodash.get';

export const iconWithCopy = (nodeData, fieldName: string, smallHeadline = false) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldDescription.processed', ''),
    variantDesktop: get(data, 'fieldSelectVariantDesktop', ''),
    variantMobile: get(data, 'fieldSelectVariantMobile', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headlineSize: smallHeadline ? 'small' : 'default',
    items: get(data, 'fieldIconWithCopy', []).map((item) => {
      return {
        icon: {
          url: get(item, 'entity.fieldIcon.url', ''),
          alt: get(item, 'entity.fieldIcon.alt', ''),
        },
        name: get(item, 'entity.fieldTitle', ''),
        description: get(item, 'entity.fieldCopy.processed', ''),
        cta: {
          url: get(item, 'entity.fieldLinkCta.url.path', ''),
          label: get(item, 'entity.fieldLinkCta.title', ''),
        },
      };
    }),
  }
}