import get from 'lodash.get';

import { ITextImageTimeline } from '@components/sections/TextImageTimeline/TextImageTimeline.d';

export const textImageTimeline = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: ITextImageTimeline = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldDescription.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    items: get(data, 'fieldTextImageTimelineItem', []).map((item) => ({
      copy: get(item, 'entity.fieldCopy.processed', ''),
      image: get(item, 'entity.fieldImage', undefined)
        ? {
            alt: get(item, 'entity.fieldImage.alt', ''),
            title: get(item, 'entity.fieldImage.title', ''),
            url: get(item, 'entity.fieldImage.url', ''),
            webp: get(item, 'entity.fieldImage.derivative.url'),
          }
        : undefined,
      cta: get(item, 'entity.fieldLinkCta', undefined)
        ? {
            label: get(item, 'entity.fieldLinkCta.title', ''),
            url: get(item, 'entity.fieldLinkCta.url.path', ''),
          }
        : undefined,
      poster: get(item, 'entity.fieldPoster', undefined)
        ? {
            alt: get(item, 'entity.fieldPoster.alt', ''),
            title: get(item, 'entity.fieldPoster.title', ''),
            url: get(item, 'entity.fieldPoster.url', ''),
            webp: get(item, 'entity.fieldPoster.derivative.url'),
          }
        : undefined,
      subheadline: get(item, 'entity.fieldSubheadline', ''),
      imageOrVideo: get(item, 'entity.fieldVariantImageOrVideo', 'image'), // return: 'image' | 'video'
      videoVariant: get(item, 'entity.fieldVariantVideoText', 'file'), // return: 'file' | 'url'
      video: get(item, 'entity.fieldVideo.entity.url', ''),
      videoUrl: get(item, 'entity.fieldVideoUrl.url.path', ''),
      schemaData: get(data, 'entity.fieldName', undefined)
        ? {
            name: get(data, 'entity.fieldName', ''),
            duration: get(data, 'entity.fieldVideoDuration', ''),
            description: get(data, 'entity.fieldVideoDescription', ''),
            uploadDate: get(data, 'entity.entityCreated', ''),
          }
        : undefined,
    })),
  };

  return componentData;
};
