import get from 'lodash.get';

export const fullScreenImage = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    description: get(data, 'fieldCopy.processed', ''),
    imageDesktop: get(data, 'fieldImageDesktopJpg') ? {
      alt: get(data, 'fieldImageDesktopJpg.alt', ''),
      url: get(data, 'fieldImageDesktopJpg.url', ''),
      title: get(data, 'fieldImageDesktopJpg.title', ''),
      webp: get(data, 'fieldImageDesktopJpg.derivative.url', ''),
    } : undefined,
    imageMobile: get(data, 'fieldImageMobileJpg') ? {
      alt: get(data, 'fieldImageMobileJpg.alt', ''),
      url: get(data, 'fieldImageMobileJpg.url', ''),
      title: get(data, 'fieldImageMobileJpg.title', ''),
      webp: get(data, 'fieldImageMobileJpg.derivative.url', ''),
    } : undefined,
  }
}
